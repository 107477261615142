import {classNames} from 'cmsch-fe-library';
import React, {FC, memo, PropsWithChildren} from 'react';

interface Props {
    container?: boolean;
}

const ContentBase: FC<PropsWithChildren<Props>> = props => {
    const {container, children} = props;

    const className = classNames(
        container ? 'container py-1' : 'p-1 px-md-3',
    );

    return (
        <div className={className}>
            {children}
        </div>
    );
};

export const Content = memo(ContentBase);
