import {RollbackOutlined} from '@ant-design/icons';
import {classNames} from 'cmsch-fe-library';
import React, {FC, memo, ReactNode, useMemo} from 'react';

import {useOurTranslation} from 'app/translations';
import {AntPageHeader} from 'common/ant';

import styles from './styles.sass';

interface Props {
    title: string;
    onBackTooltip?: string;
    extra?: ReactNode;
    smallerTitleOnPhone?: boolean;
    extraFullWidthOnPhone?: boolean;
    loading?: boolean;
    onBack?(): void;
}

const PageHeaderBase: FC<Props> = props => {
    const {title, onBackTooltip, extra, smallerTitleOnPhone, extraFullWidthOnPhone, loading, onBack} = props;

    const {t} = useOurTranslation('common');

    const backIcon = useMemo(() => (
        <span
            title={onBackTooltip}
            className={classNames(styles.backIcon, 'd-flex')}
            data-test-id="back-button"
        >
            <RollbackOutlined />
        </span>
    ), [onBackTooltip]);

    const className = classNames(
        styles.pageHeader,
        'pl-1',
        smallerTitleOnPhone && styles.smallerTitleOnPhone,
        extraFullWidthOnPhone && styles.extraFullWidthOnPhone,
    );

    return (
        <div
            className={className}
            data-test-id="screen-title"
        >
            <AntPageHeader
                title={loading ? `${t('loading')}` : title}
                backIcon={backIcon}
                extra={extra}
                onBack={onBack}
            />
        </div>
    );
};

export const PageHeader = memo(PageHeaderBase);
