import {classNames, toggleItem} from 'cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import React, {createRef, FC, memo, ReactNode, RefObject, useCallback, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';

import {Role} from 'api/gen/Role';
import {useOurTranslation} from 'app/translations';
import {Ant} from 'common/ant';
import {ICONS} from 'common/buttons/icons';
import {Environment} from 'common/layout/types/environment';
import {MenuItem} from 'common/layout/types/menu-item';

import {AppLogo} from '../app-logo';
import {LanguageOption, LanguageSelect} from './language-select';
import {buildSubMenuItems, isActive, MainMenuItem} from './main-menu-item';
import {SideMenu} from './side-menu';
import {SmallMenu} from './small-menu';

import styles from './styles.sass';

export type MenuType = 'full' | 'side' | 'small';

export interface Props {
    menuItems: Array<MenuItem>;
    userRole: Role;
    locationPath: string;
    homePath: string;
    isUserLoading: boolean;
    menuType: MenuType;
    languageOptions: Array<LanguageOption>;
    selectedLanguage?: LanguageOption;
    environment: Environment;
    isSideMenuCollapsed?: boolean;

    onMenuItemClick?(): void;
    onLanguageChange?(language: string): void;
}

interface RenderMenuItemsProps {
    menuItems: Array<MenuItem>;
    userRole: Role;
    locationPath: string;
    overflowedMenuItemsKeys: Array<string>;
    actionIconsRef: RefObject<HTMLDivElement>;
    toggleOverflowedMenuItem(key: string): void;
}

const renderMenuItems = ({
    actionIconsRef,
    locationPath,
    userRole,
    menuItems,
    overflowedMenuItemsKeys,
    toggleOverflowedMenuItem,
}: RenderMenuItemsProps): ReactNode =>
    menuItems.map((menuItem: MenuItem) => !menuItem.hidden && menuItem.permissions.includes(userRole) && (
        <MainMenuItem
            key={menuItem.key}
            locationPath={locationPath}
            userRole={userRole}
            menuItem={menuItem}
            visible={!overflowedMenuItemsKeys.includes(menuItem.key)}
            actionIconsRef={actionIconsRef}
            toggleOverflowedMenuItem={toggleOverflowedMenuItem}
        />
    ));

const MainMenuBase: FC<Props> = props => {
    const {
        menuItems,
        userRole,
        locationPath,
        homePath,
        isUserLoading,
        menuType,
        languageOptions,
        selectedLanguage,
        environment,
        isSideMenuCollapsed,
        onMenuItemClick,
        onLanguageChange,
    } = props;

    const {t} = useOurTranslation('user/login');
    const [overflowedMenuItemsKeys, setOverflowedMenuItems] = useState<Array<string>>([]);

    const toggleOverflowedMenuItem = useCallback((key: string) => {
        setOverflowedMenuItems(toggleItem(key)(overflowedMenuItemsKeys));
    }, [overflowedMenuItemsKeys]);

    const languageSelect = useMemo(() => selectedLanguage && onLanguageChange
        ? (
            <LanguageSelect
                isSideMenu={false}
                languageOptions={languageOptions}
                selectedLanguage={selectedLanguage}
                onLanguageChange={onLanguageChange}
            />
        )
        : null, [languageOptions, onLanguageChange, selectedLanguage]);

    const actionIconsRef = createRef<HTMLDivElement>();

    const overflowedMenuItems = useMemo(() =>
        menuItems.filter(x => overflowedMenuItemsKeys.includes(x.key)),
    [menuItems, overflowedMenuItemsKeys]);

    const overflowedMenuClass = useMemo(() => classNames(
        styles.menuItem,
        styles.moreButton,
        'd-inline-block',
        isActive(locationPath, undefined, overflowedMenuItems) && styles.active,
    ), [locationPath, overflowedMenuItems]);

    const overflowedMenu = useMemo(() => (
        <Ant.Dropdown
            overlay={(
                <Ant.Menu items={buildSubMenuItems(overflowedMenuItems, locationPath, userRole)} />
            )}
            placement="bottomRight"
            arrow
        >
            <div className={overflowedMenuClass}>
                {ICONS.moreOutlined}
            </div>
        </Ant.Dropdown>
    ), [locationPath, overflowedMenuClass, overflowedMenuItems, userRole]);

    const actionIcons = useMemo(() => (
        <div
            className={classNames('position-absolute', styles.actionIcons)}
            ref={actionIconsRef}
        >
            {languageSelect}
            {!isEmpty(overflowedMenuItemsKeys) && menuType === 'full' && overflowedMenu}
        </div>
    ), [actionIconsRef, languageSelect, menuType, overflowedMenu, overflowedMenuItemsKeys]);

    switch (menuType) {
        case 'side':
            return (
                <SideMenu
                    isUserLoading={isUserLoading}
                    menuItems={menuItems}
                    locationPath={locationPath}
                    userRole={userRole}
                    isSideMenuCollapsed={isSideMenuCollapsed}
                    onMenuItemClick={onMenuItemClick}
                />
            );

        case 'small':
            return (
                <SmallMenu
                    appLogo={(
                        <AppLogo
                            environment={environment}
                            isSmallMenu
                        />
                    )}
                    actionIcons={actionIcons}
                />
            );

        case 'full':
            return (
                <div className={styles.menuContainer}>
                    {isUserLoading
                        ? (
                            <div className={styles.loadingHeader}>{t('isUserLoading')}</div>
                        ) : (
                            <div className="text-nowrap position-relative overflow-hidden d-flex">
                                <div className={styles.menuItem}>
                                    <Link
                                        to={homePath}
                                        onClick={onMenuItemClick}
                                    >
                                        <AppLogo environment={environment} />
                                    </Link>
                                </div>
                                {renderMenuItems({
                                    menuItems,
                                    locationPath,
                                    userRole,
                                    overflowedMenuItemsKeys,
                                    actionIconsRef,
                                    toggleOverflowedMenuItem,
                                })}
                                {actionIcons}
                            </div>

                        )}
                </div>
            );
    }
};

export const MainMenu = memo(MainMenuBase);
