/* eslint-disable react/jsx-props-no-spreading */
import {ColProps} from 'antd/lib/grid';
import {classNames} from 'cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import {gutter} from 'app/consts';
import {Ant} from 'common/ant';

import styles from './styles.sass';

interface Props {
    message?: string;
    actions?: ReactNode;
    marginBottom?: boolean;
    secondaryItem?: ReactNode;
    messageColProps?: ColProps;
    actionsColProps?: ColProps;
    secondaryItemColProps?: ColProps;
}

const InfoPanelBase: FC<Props> = props => {
    const {
        message,
        actions,
        marginBottom,
        secondaryItem,
        messageColProps,
        actionsColProps,
        secondaryItemColProps,
    } = props;

    const className = classNames(
        styles.infoPanel,
        marginBottom && 'mb-2',
    );

    return (
        <div
            className={className}
            data-test-id="info-panel"
        >
            <Ant.Row
                justify="space-between"
                gutter={[gutter, gutter]}
            >
                <Ant.Col {...messageColProps}>
                    <div
                        className="align-items-center h-100 d-flex"
                        data-test-id="info-panel-message"
                    >
                        {message}
                    </div>
                </Ant.Col>
                {secondaryItem && (
                    <Ant.Col {...secondaryItemColProps}>
                        {secondaryItem}
                    </Ant.Col>
                )}
                <Ant.Col {...actionsColProps}>
                    {actions}
                </Ant.Col>
            </Ant.Row>
        </div>
    );
};

export const InfoPanel = memo(InfoPanelBase);
