import {classNames} from 'cmsch-fe-library';
import React, {FC, memo} from 'react';

import {Environment} from 'common/layout/types/environment';

import styles from './styles.sass';

import logoDevelopment from './img/cmsch.svg';

export const environmentLogoMap: Record<Environment, string> = {
    development: logoDevelopment,
};

interface Props {
    environment: Environment;
    isSmallMenu?: boolean;
}

const AppLogoBase: FC<Props> = ({environment, isSmallMenu}) => (
    <div className={classNames(styles.logoWrapper, isSmallMenu && 'd-inline-block')}>
        <div
            data-test-id="app-logo"
            className={styles.logo}
            // eslint-disable-next-line react/no-danger,@typescript-eslint/naming-convention
            dangerouslySetInnerHTML={{__html: environmentLogoMap[environment]}}
        />
        <div className={classNames('position-relative', 'd-inline-block', styles.logoText)}>
            <span className="position-absolute">
                Rating
            </span>
        </div>
    </div>
);

export const AppLogo = memo(AppLogoBase);
