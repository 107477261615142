import {classNames} from 'cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import styles from './styles.sass';

export interface ListItem {
    id: number;
    selected: boolean;
    node: ReactNode;

    action(): void;
}

interface ItemProps {
    item: ListItem;
    testId: string;
}

const Item: FC<ItemProps> = ({item, testId}) => (
    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
        <div
            data-test-id={`${testId} ${item.selected ? 'selected' : ''}`}
            className={classNames(styles.item, item.selected && styles.selected)}
            onClick={item.action}
        >
            {item.node}
        </div>
    </div>
);

interface Props {
    items: Array<ListItem>;
    testId: string;
}

const ListBase: FC<Props> = props => {
    const {items, testId} = props;

    return (
        <div
            className={styles.list}
            data-test-id={testId}
        >
            <div className="row">
                {items.map(i => (
                    <Item
                        testId={`${testId} item`}
                        item={i}
                        key={i.id}
                    />
                ))}
            </div>
        </div>
    );
};

export const List = memo(ListBase);
