import {classNames} from 'cmsch-fe-library';
import React, {CSSProperties, FC, memo, ReactNode} from 'react';

import {Ant} from 'common/ant';

import styles from './styles.sass';

interface Props {
    children: ReactNode;
    title?: ReactNode;
    fullWidth?: boolean;
    titleSize?: 'big';
    noPadding?: boolean;
    extra?: ReactNode;
    small?: boolean;
    noPaddingTop?: boolean;
    marginBottom?: boolean;
    smallSidePadding?: boolean;
    testId: string;
}

const CardBase: FC<Props> = props => {
    const {
        title,
        children,
        fullWidth,
        titleSize,
        noPadding,
        extra,
        small,
        noPaddingTop,
        marginBottom,
        smallSidePadding,
        testId,
    } = props;

    const className = classNames(
        fullWidth ? 'w-100' : 'w-auto',
        titleSize === 'big' && styles.titleSizeBig,
        styles.card,
        marginBottom && 'mb-2',
        smallSidePadding && styles.smallSidePadding,
    );

    const bodyStyle: CSSProperties = {
        padding: noPadding ? 0 : undefined,
        paddingTop: noPaddingTop ? 0 : undefined,
    };

    return (
        <Ant.Card
            title={title}
            className={className}
            bodyStyle={bodyStyle}
            extra={extra}
            size={small ? 'small' : undefined}
            data-test-id={testId}
        >
            {children}
        </Ant.Card>
    );
};

export const Card = memo(CardBase);
